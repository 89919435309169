import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { i18n } from "../../App";
import LoadingContent from "../LoadingContent";
import "../../assets/style/news.css";
import GrandPrixRace from "../GrandPrix/GrandPrixRace";
import DateFormat from "../DateFormat";
import CarouselNews from "./CarouselNews";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface NewInterface {
  id: number;
  title: string;
  caption: string;
  championship_id: string;
  image: string;
  content: string;
  date: string;
  grand_prix_event: null | { name: string };
}

const New = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const [new_, setNew] = useState<NewInterface>();
  const [show, setShow] = useState(false);
  const [modalImageAlt, setModalImageAlt] = useState("");
  const [modalImageUrl, setModalImageUrl] = useState("");
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/news/getNew`,
        {
          params: { id, language: i18n.language },
        }
      )
      .then(({ data }) => {
        setNew(data);
        document.title = "LVF1 - " + data.title;
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [id, i18n.language]);

  const openModal = (alt: string | null, src: string | null) => {
    if (window.innerWidth < 750) return;
    if (alt != null) setModalImageAlt(alt);
    if (src != null) setModalImageUrl(src);
    setShow(true);
  };

  const handleCloseLogoutModal = () => {
    setShow(false);
  };

  useEffect(() => {
    const contentContainer = document.getElementById("new-inner-container");
    let images: NodeListOf<HTMLImageElement>;
    if (contentContainer) {
      images = contentContainer.querySelectorAll("img");
      if (images == null) return;
      images.forEach((image) => {
        if (image.closest(".preseason")) return;
        const alt = image.getAttribute("alt");
        const src = image.getAttribute("src");
        // Crea una nueva imagen en memoria para obtener su anchura real
        const tempImage = new Image();
        if (src != null) tempImage.src = src;
        tempImage.onload = () => {
          image.addEventListener("click", () => openModal(alt, src));
        };
      });
    }

    return () => {
      if (images == null) return;
      images.forEach((image) => {
        const alt = image.getAttribute("alt");
        const src = image.getAttribute("src");
        // Crea una nueva imagen en memoria para obtener su anchura real
        const tempImage = new Image();
        if (src != null) tempImage.src = src;
        tempImage.onload = () => {
          image.addEventListener("click", () => openModal(alt, src));
        };
      });
    };
  }, [new_]);

  if (new_ == null) return <LoadingContent />;

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="new-container">
        <div id="new-inner-container">
          <h3>{new_.title}</h3>
          <small>{new_.caption}</small>
          <div className="img-container">
            <img src={new_.image} alt={new_.title} />
          </div>
          <DateFormat
            date={new_.date}
            format={"short_year"}
            disaggregated={true}
          />
          <div
            id="content-container"
            dangerouslySetInnerHTML={{ __html: new_.content }}
          ></div>
          {new_.grand_prix_event !== null && (
            <>
              <div id="detailed-container">
                <Button variant="primary" id="detailed-results">
                  <Link
                    to={`${t(
                      "routes:GrandPrix"
                    )}/${new_.grand_prix_event.name.replace(/\s/g, "")}`}
                  >
                    <FontAwesomeIcon icon={faEye} /> {t("DetailedResults")}
                  </Link>
                </Button>
              </div>
              <GrandPrixRace
                grand_prix_name={new_.grand_prix_event.name.replace(/\s/g, "")}
              />
            </>
          )}
        </div>
        <CarouselNews id={new_.id} />
      </div>
      <Modal id="modal-news-image" show={show} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalImageAlt == "" ? new_.title : modalImageAlt}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImageUrl} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default New;
