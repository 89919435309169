import "../../assets/style/grandprix.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircuitLayout from "../../components/CircuitLayout";
import {
  faCircleInfo,
  faCalendarDay,
  faLocationPin,
  faRuler,
  faBacon,
  faMapLocation,
  faTrophy,
  faGaugeHigh,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { i18n } from "../../App";
import DateFormat from "../../components/DateFormat";
import Flag from "../../components/Flag";
import { DriverLink } from "../../components/FlagLink";
import ErrorPage from "../ErrorPage";
import { Link, useNavigate } from "react-router-dom";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import LoadingContent from "../../components/LoadingContent";
import SummaryData from "../../components/Historic/SummaryData";
import SummaryDrivers from "./index/SummaryDrivers";

const GrandPrix = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { grandprix } = useParams();
  const { t } = useTranslation();
  const [grandPrix, setGrandPrix] = useState({
    name: "",
    pole_video: "",
    gp_video: "",
    conference: "",
    circuit_id: null,
    date: new Date(),
    circuit_name: "",
    layout: null,
    turns: 0,
    length: 0,
    flag_code: "unk",
    country: "",
    id: -1,
    champ_name: "",
  });
  const [milestones, setMilestones] = useState([]);
  const [typeRaces, setTypeRaces] = useState(-1);
  const [drivers, setDrivers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [grandsPrixNames, setGrandsPrixNames] = useState([]);
  const [winner, setWinner] = useState([]);
  const [report, setReport] = useState<any>([]);

  const [poleman, setPoleman] = useState([]);
  const [fastestLap, setFastestLap] = useState([]);
  const [photoGP, setPhotoGP] = useState("");

  const [widthCont, setWidthCont] = useState("col-md-8");
  const [circuitContainer, setCircuitContainer] = useState(
    "gp-results-layout-container"
  );

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getGrandPrixByName`,
        {
          params: {
            name: grandprix,
            language: i18n.language,
          },
        }
      )
      .then(({ data }) => {
        document.title = "LVF1 - " + data.grand_prix.name;
        setMilestones(data.milestones);
        setDrivers(data.drivers);
        setTeams(data.teams);
        setGrandsPrixNames(data.grandprix_names);
        setWinner(data.winner);
        setPoleman(data.poleman);
        setFastestLap(data.fastestLap);
        setGrandPrix(data.grand_prix);
        setTypeRaces(data.type_races);
        setReport(data.report);
        if (data.grand_prix.gp_video === null) {
          if (data.hasImage != null && data.hasImage)
            setPhotoGP(`/assets/ImagesGP/${data.grand_prix.id}.jpg`);
          else if (
            //if we don't have the videos neither, then we will adjust the other divs'
            data.grand_prix.pole_video === null &&
            data.grand_prix.gp_video === null
          ) {
            setWidthCont("col-md-12");
          }
        }
        if (data.milestones.length == 0) {
          setCircuitContainer("gp-results-layout-containerx2");
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, [i18n.language, grandprix]);

  const getMilestoneLinked = (milestone: string) => {
    /* DRIVERS */
    let milestone_array = [milestone];
    let milestone_transformed_drivers = drivers.reduce(
      (previous_milestone: any, driver: any) => {
        let return_milestone = previous_milestone.slice(); //to remove the reference
        for (var i = 0; i < previous_milestone.length; i++) {
          if (
            previous_milestone[i]
              .toLowerCase()
              .includes(driver.username.toLowerCase())
          ) {
            var re = new RegExp("(" + driver.username + ")", "g");
            var aux = return_milestone[i].split(re);
            return_milestone.splice(i, 1); //removing the previous
            for (var j = 0; j < aux.length; j++) {
              if (aux[j].toLowerCase() == driver.username.toLowerCase()) {
                aux[j] = "{DR}" + driver.username;
              }
              return_milestone.splice(i + j, 0, aux[j]);
            }
          }
        }
        //Cap first letter
        return_milestone[0] =
          return_milestone[0].charAt(0).toUpperCase() +
          return_milestone[0].slice(1);

        return return_milestone;
      },
      milestone_array
    );

    /* TEAMS */
    let milestone_transformed_teams = teams.reduce(
      (previous_milestone: any, team: any) => {
        let return_milestone = previous_milestone.slice(); //to remove the reference
        for (var i = 0; i < previous_milestone.length; i++) {
          if (previous_milestone[i].includes(team.name)) {
            var re = new RegExp("(" + team.name + ")", "g");
            var aux = return_milestone[i].split(re);
            return_milestone.splice(i, 1); //removing the previous
            for (var j = 0; j < aux.length; j++) {
              if (aux[j] === team.name) aux[j] = "{TE}" + aux[j];
              return_milestone.splice(i + j, 0, aux[j]);
            }
          }
        }
        return return_milestone;
      },
      milestone_transformed_drivers
    );

    /* GPS */
    let milestone_transformed_gps = grandsPrixNames.reduce(
      (previous_milestone: any, gp: any) => {
        let return_milestone = previous_milestone.slice(); //to remove the reference
        for (var i = 0; i < previous_milestone.length; i++) {
          if (previous_milestone[i].includes(gp.name)) {
            var re = new RegExp("(" + gp.name + ")", "g");
            var aux = return_milestone[i].split(re);
            return_milestone.splice(i, 1); //removing the previous
            for (var j = 0; j < aux.length; j++) {
              if (aux[j] === gp.name) aux[j] = "{GP}" + aux[j];
              return_milestone.splice(i + j, 0, aux[j]);
            }
          }
        }
        return return_milestone;
      },
      milestone_transformed_teams
    );

    let output_milestone = <></>;
    const output_milestone2 = milestone_transformed_gps.reduce(
      (previous_milestone: any, current_position: any) => {
        if (current_position.startsWith("{DR}")) {
          let driver = current_position.split("{DR}")[1];
          return (
            <>
              {previous_milestone}
              <Link
                to={`${t("routes:Driver")}/${encodeURIComponent(driver)}`}
                className="driver"
              >
                {driver}
              </Link>
            </>
          );
        } else if (current_position.startsWith("{TE}")) {
          let team = current_position.split("{TE}")[1];
          return (
            <>
              {previous_milestone}
              <Link
                to={`${t("routes:Team")}/${encodeURIComponent(team)}`}
                className="driver"
              >
                {team}
              </Link>
            </>
          );
        } else if (current_position.startsWith("{GP}")) {
          let gp = current_position.split("{GP}")[1];
          return (
            <>
              {previous_milestone}
              <Link
                to={`${t("routes:GrandPrix")}/${encodeURIComponent(
                  gp.replace(/ /g, "")
                )}`}
                className="driver"
              >
                {gp}
              </Link>
            </>
          );
        }
        return (
          <>
            {previous_milestone}
            <span>{current_position}</span>
          </>
        );
      },
      output_milestone
    );
    return output_milestone2;
  };
  if (loading) return <LoadingContent />;
  if (grandPrix.name === "" || grandprix == null) return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={1} />
      <div id="index-gp-container" className="row">
        <div className={widthCont}>
          <div
            className="flex"
            id={grandPrix.conference !== null ? "gp-data-withconf" : "gp-data"}
          >
            <div className="col-md-6">
              <div id="gp-overview" className="frame-turqoise frame-sm">
                <p className="main-title noBottomMargin frame-title">
                  {t("Summary").toUpperCase()}
                </p>
                <div id="summary-data-content">
                  <p className="flex">
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <b>{t("Date")}: </b>
                    <DateFormat
                      date={grandPrix.date.toString()}
                      format={"normal"}
                    />
                  </p>
                  <p className="flex">
                    <FontAwesomeIcon icon={faLocationPin} />
                    <b>{t("Circuit")}: </b>
                    <span>
                      {grandPrix.circuit_name}{" "}
                      {grandPrix.layout != null && `(${grandPrix.layout})`}
                    </span>
                  </p>
                  {grandPrix.turns > 0 && (
                    <p className="flex">
                      <FontAwesomeIcon icon={faBacon} />
                      <b>{t("Turns")}: </b>
                      <span>{grandPrix.turns}</span>
                    </p>
                  )}
                  <p className="flex">
                    <FontAwesomeIcon icon={faRuler} />
                    <b>{t("Length")}: </b>
                    <span>
                      {parseFloat(grandPrix.length.toString()).toFixed(3)} km
                    </span>
                  </p>
                  {grandPrix.country !== null && (
                    <p className="flex">
                      <FontAwesomeIcon icon={faMapLocation} />
                      <b>{t("Location")}: </b>
                      <span>
                        <Flag
                          flag_code={grandPrix.flag_code}
                          name={grandPrix.country}
                        />
                        {t(`countries:${grandPrix.country}`)}
                      </span>
                    </p>
                  )}
                  {typeRaces !== 1 && poleman != null && (
                    <SummaryDrivers
                      code="PO"
                      translation="Polesitter"
                      drivers={Array.isArray(poleman) ? poleman : [poleman]}
                      icon={faGaugeHigh}
                    />
                  )}
                  <SummaryDrivers
                    code="WI"
                    translation="Winner"
                    drivers={winner}
                    icon={faTrophy}
                  />
                  <SummaryDrivers
                    code="FL"
                    translation="HotLap"
                    drivers={fastestLap}
                    icon={faGaugeHigh}
                  />
                </div>
                {report.length > 0 && (
                  <div
                    className="race-report flex"
                    onClick={() => {
                      navigate(
                        `${t("routes:News")}/${
                          grandPrix.champ_name == null
                            ? "LVF1"
                            : grandPrix.champ_name.replace(/ /g, "")
                        }/${report[0].id}`
                      );
                    }}
                  >
                    <img
                      alt={grandPrix.name}
                      src={`/assets/News/${report[0].id}/Image.jpg`}
                    />
                    <div>
                      <h4>
                        {i18n.language === "es"
                          ? report[0].title_es
                          : report[0].title_en}
                      </h4>
                      <span>
                        {i18n.language === "es"
                          ? report[0].caption_es
                          : report[0].caption_en}
                      </span>
                    </div>
                    <div className="read-more">{t("Report")}</div>
                  </div>
                )}
                {grandPrix.conference !== null && (
                  <div id="press-conference">
                    <iframe
                      id="audio_53222826"
                      scrolling="no"
                      height="200"
                      width="100%"
                      src={`https://www.ivoox.com/player_ej_${grandPrix.conference}_6_1.html?c1=101010`}
                      loading="lazy"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 flex-around">
              <div id={circuitContainer} className="frame-turqoise frame-sm">
                <p className="main-title frame-title noBottomMargin">
                  {grandPrix.circuit_name.toUpperCase()}
                </p>
                <div>
                  {grandPrix.circuit_id !== null && (
                    <CircuitLayout
                      circuit_id={grandPrix.circuit_id}
                      id_canvas={`circuit-${grandPrix.id}`}
                    />
                  )}
                </div>
              </div>
              {milestones.length > 0 && (
                <div
                  id="milestones-container"
                  className="frame-turqoise frame-sm"
                >
                  <p className="main-title frame-title noBottomMargin">
                    {t("Milestones").toUpperCase()}
                  </p>
                  <div className="milestones-data">
                    {milestones.map((milestone: any, index) => {
                      return (
                        <p key={index}>
                          <FontAwesomeIcon icon={faCircleInfo} />{" "}
                          {getMilestoneLinked(milestone.milestone)}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          {grandPrix.pole_video && (
            <div className="video-container frame-turqoise frame-sm">
              <p className="main-title frame-title noBottomMargin">
                {(typeRaces !== 1
                  ? t("PoleVideo")
                  : t("FastestLapVideo")
                ).toUpperCase()}
              </p>
              <iframe
                id="ytplayer"
                width="100%"
                allowFullScreen
                src={`https://www.youtube.com/embed/${grandPrix.pole_video}`}
              />
            </div>
          )}
          {grandPrix.gp_video && (
            <div className="video-container frame-turqoise frame-sm">
              <p className="main-title frame-title noBottomMargin">
                {t("GPVideo").toUpperCase()}
              </p>
              <iframe
                id="ytplayer"
                width="100%"
                allowFullScreen
                src={`https://www.youtube.com/embed/${grandPrix.gp_video}`}
              />
            </div>
          )}
          {photoGP !== "" && (
            <div className="image-container frame-turqoise frame-sm">
              <p className="main-title frame-title noBottomMargin">
                {t("PhotoGP").toUpperCase()}
              </p>
              <div className="flex-center">
                <img alt={grandPrix.name} src={photoGP} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GrandPrix;
