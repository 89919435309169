import axios from "axios";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContent from "../../components/LoadingContent";
import { useUser } from "../../components/UserContext";
import "../../assets/style/transfers.css";

const Transfers = ({
  setUpdateNotification,
  isSecondChamp,
}: {
  setUpdateNotification: Dispatch<SetStateAction<boolean>>;
  isSecondChamp?: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  const [data, setData] = useState<{
    picker_list: { driver: { username: string }; state: number }[];
    final_titular_drivers: {
      team_championship_id: number;
      drivers: DriverEntry[];
    }[];
  }>();
  document.title = "LVF1 - " + t("Transfers");
  const [showSignButton, setShowSignButton] = useState(false);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [teamSelected, setTeamSelected] = useState<DriverEntry>();
  const [number, setNumber] = useState();
  const [error, setError] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/getNewChampionshipData`,
        {
          params: {
            isSecondChamp: isSecondChamp ? 1 : 0,
            user,
          },
        }
      )
      .then(({ data }) => {
        data.final_titular_drivers.sort(
          (a: any, b: any) => a.drivers[0].number - b.drivers[0].number
        );
        setData(data);
        setShowSignButton(data.available);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, [user, update, isSecondChamp]);

  const skipShift = () => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/skipShift`,
        {
          user,
          token: JSON.parse(token).token,
          isSecondChamp,
        }
      )
      .then(() => {
        toast.success(`${t("TurnShifted")}`);

        setUpdateNotification((prev) => {
          return !prev;
        });
        setUpdate(!update);
      })
      .catch(({ response }) => {
        console.log(response);
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };

  const sign = (assignment: DriverEntry) => {
    setTeamSelected(assignment);
    handleShow();
  };

  const checkNumber = () => {
    setError(0);
    if (
      (teamSelected?.number == null && number == null) ||
      (teamSelected?.number == null && number == "") ||
      number == "0"
    ) {
      setError(1);
      return false;
    }
    if (data != null && number != null) {
      let number_exists = false;
      data.final_titular_drivers.map((team) => {
        team.drivers.map((driver: any) => {
          if (driver != null && driver.number === parseInt(number)) {
            setError(2);
            number_exists = true;
            return;
          }
        });
      });
      if (number_exists) return false;
    }
    if (
      data != null &&
      number != null &&
      parseInt(number) === 1 &&
      data.picker_list.length > 0 &&
      user != data.picker_list[0].driver.username
    ) {
      setError(3);
      return false;
    }
    return true;
  };

  const signPost = () => {
    if (!checkNumber()) return;
    let token = window.localStorage.getItem("userLogged");
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/signTeam`,
        {
          user,
          token: JSON.parse(token).token,
          id: teamSelected?.assignment_id,
          number,
          isSecondChamp,
        }
      )
      .then(() => {
        toast.success(`${t("TeamSignUpOK")}`);

        setUpdateNotification((prev) => {
          return !prev;
        });
        setUpdate(!update);
        setShow(false);
      })
      .catch(({ response }) => {
        console.log(response);
        toast.error(`${t("Error!")}`);
        throw new Error(response);
      });
  };

  if (data == null) return <LoadingContent />;

  const showList =
    data.picker_list != null &&
    data.picker_list.length > 0 &&
    data.picker_list.filter(
      (list: { state: number; driver: { username: string } }) =>
        list.state === 0
    ).length > 0;
  return (
    <div className="full-height padding-lg app-container flex-center-top page-transition">
      <div id="transfers-container" className="flex-center">
        {showList && (
          <div id="picker-list">
            <h3 className="text-center">{t("ListOfSelection")}</h3>
            <div className="form-group">
              {data.picker_list.map((item, index) => {
                return (
                  <p key={index} className={`transfers${item.state}`}>
                    <span className="backgrounded-title">{index + 1}.</span>{" "}
                    {item.driver.username}
                  </p>
                );
              })}
            </div>
            {data.picker_list.find(
              (list: { state: number; driver: { username: string } }) =>
                list.state === 0 && list.driver.username === user
            ) && (
              <div className="text-center">
                <button className="btn btn-warning" onClick={skipShift}>
                  {t("SkipShift")}
                </button>
              </div>
            )}
          </div>
        )}

        <div
          id="teams-list"
          className={`row ${showList ? "" : "full-transf"}`}
          style={{
            width: showList ? "calc(100% - 315px)" : "100%",
          }}
        >
          <h3 className="text-center">{t("Teams")}</h3>
          {data.final_titular_drivers.map((team, index: number) => {
            return (
              <div key={index} className={`col-md-3 team-transfer`}>
                <Link
                  to={`${t("routes:Team")}/${encodeURIComponent(
                    team.drivers[0].team_name
                  )}`}
                >
                  <img
                    alt={team.drivers[0].team_name}
                    title={team.drivers[0].team_name}
                    src={`/assets/TeamChampionship/${team.team_championship_id}.png`}
                  />
                </Link>
                <div>
                  {team.drivers.map((driver_, index: number) => {
                    return (
                      <div key={index} className="num-driver">
                        <span
                          style={{
                            background: driver_.main_color,
                            color: driver_.secondary_color,
                          }}
                        >
                          {driver_.number}
                        </span>
                        {driver_.username == null ? (
                          <>
                            <img
                              title={t("Sign")}
                              className="arrows-gif"
                              src={require("../../assets/Images/arrows.gif")}
                            />
                            {showSignButton && (
                              <button
                                className="sign-button button-green btn btn-primary"
                                onClick={() => sign(driver_)}
                              >
                                {t("Sign")}
                              </button>
                            )}
                          </>
                        ) : (
                          <Link
                            className="driver"
                            title={driver_.username}
                            to={`${t("routes:Driver")}/${encodeURIComponent(
                              driver_.username
                            )}`}
                          >
                            {driver_.username}
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("Sign")} - {teamSelected?.team_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {teamSelected?.number == null ? t("TextSign") : t("TextSign2")}
          {teamSelected?.number == null && (
            <Form.Control
              required
              type="number"
              onChange={(e: any) => {
                setNumber(e.target.value);
              }}
            />
          )}
          <p className="red">
            {error == 1
              ? t("NumberBad")
              : error == 2
              ? t("NumberChoosen")
              : error == 3
              ? t("NumberBad2")
              : ""}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button variant="primary" className="button-green" onClick={signPost}>
            {t("Sign")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Transfers;
